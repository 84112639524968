import { createSlice } from "@reduxjs/toolkit";
import { ROUTES, appConfig } from "./../../configs";
import defaultState from "./../../constant/defaultState";

export const authSlice = createSlice({
  name: "auth",
  initialState: defaultState.toolkit.auth,

  reducers: {
    handleLogin: (state, action) => {
      let tempState = {
        ...state,
        previousAuth: {
          token: state.token,
          user: state.user,
        },
        ...action.payload,
      };
      window.localStorage.setItem(
        appConfig.localStorage.token,
        action.payload.token
      );
      return tempState;
    },

    handleLogout: (state, action) => {
      const previousAuth = state.previousAuth || {};

      window.localStorage.removeItem(appConfig.localStorage.token);

      if (previousAuth.token) {
        window.localStorage.setItem(
          appConfig.localStorage.token,
          previousAuth.token
        );
      }

      window.location.href = ROUTES.LOGIN;

      return {
        ...defaultState.toolkit.auth,
        token: previousAuth.token,
        user: previousAuth.user || null,
      };
    },

    savePreviousAuth: (state, action) => {
      let previousAuth = {
        token: state.token,
        user: state.user,
      };
      window.localStorage.setItem(
        appConfig.localStorage.token,
        action.payload.token
      );
      return { previousAuth, ...action.payload };
    },
    handleEmployeeShortLeave: (state, action) => {
      let tempState = {
        ...state,
        employeeShortLeaveData: [...action.payload],
      };
      return tempState;
    },

    handleCurrentTaskFlag: (state, action) => {
      state.currentTaskFlag = action.payload || false;
    },

    handleSideBarHideShowFlag: (state, action) => {
      state.sideBarHideShowFlag = action.payload || false;
    },
    clearAuthStates: (state, action) => {
      window.localStorage.setItem(appConfig.localStorage.token, "");
      return { ...defaultState.toolkit.auth };
    },
  },
});

export default authSlice.reducer;
export const {
  handleLogin,
  savePreviousAuth,
  handleLogout,
  handleEmployeeShortLeave,
  handleCurrentTaskFlag,
  handleSideBarHideShowFlag,
  clearAuthStates,
} = authSlice.actions;
