import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HELPER } from "../services";
import { ROUTES } from "../configs";
import { useSelector } from "react-redux";
import { AllPermissions } from "../services/enums";

const Navdata = () => {
  const history = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const auth = useSelector((state) => state?.auth?.user);

  const [menus, setMenus] = useState([
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "dashboard",
      label: "Dashboard",
      icon: "ri-dashboard-2-line",
      link: ROUTES.DASHBOARD,
      role: "user",
      isActive: false,
      click: function (e) {
        e.preventDefault();
        changeMenu("dashboard");
      },
    },
    {
      id: "analysis_dashboards",
      label: "Analysis Dashboard",
      icon: "ri-dashboard-line",
      link: ROUTES.ANALYSIS_DASHBOARD,
      parentId: "apps",
      permission: AllPermissions[37]?.name,

      isActive: false,
      click: function (e) {
        e.preventDefault();
        changeMenu("analysis_dashboards");
      },
    },
    {
      id: "meeting_dashboard",
      label: "Meeting Dashboard",
      icon: "ri-group-2-line",
      link: ROUTES.MEETING_DASHBOARD,
      parentId: "apps",
      isActive: false,
      permission: AllPermissions[7]?.name,
      click: function (e) {
        e.preventDefault();
        changeMenu("meeting_dashboard");
      },
    },
    {
      id: "employee_dashboard",
      label: "Report Dashboard",
      icon: "ri-survey-line",
      link: ROUTES.EMPLOYEE_DASHBOARD,
      parentId: "apps",
      isActive: false,
      permission: AllPermissions[20]?.name,
      click: function (e) {
        e.preventDefault();
        changeMenu("employee_dashboard");
      },
    },
    {
      id: "projects",
      label: "Project Dashboard",
      link: ROUTES.PROJECTS,
      icon: " ri-product-hunt-line",
      parentId: "apps",
      isActive: false,
      role: "employeeRole",
      permission: AllPermissions[8]?.name,
      click: function (e) {
        e.preventDefault();
        changeMenu("projects");
      },
    },
    {
      id: "tasks-boards",
      label: "Task Board",
      link: ROUTES.TASK_BOARD,
      icon: "ri-survey-line",
      parentId: "apps",
      isActive: false,
      permission: AllPermissions[35]?.name,
      role: "employeeRole",
      click: function (e) {
        e.preventDefault();
        changeMenu("tasks-boards");
      },
    },
    {
      id: "tasks-managment",
      label: "Task Management",
      link: ROUTES.TASK_MANAGMENT,
      icon: "ri-task-fill",
      parentId: "apps",
      isActive: false,
      permission: AllPermissions[35]?.name,
      role: "employeeRole",
      click: function (e) {
        e.preventDefault();
        changeMenu("tasks-managment");
      },
    },
    {
      id: "userManagement",
      label: "User Management",
      icon: " ri-user-2-line",
      link: "/#",
      isActive: false,
      isChildItem: true,
      click: function (e) {
        e.preventDefault();
        changeMenu("userManagement");
      },
      subItems: [
        {
          id: "admin",
          label: "Admin",
          link: ROUTES.USERS,
          parentId: "apps",
          isActive: false,
          permission: AllPermissions[31]?.name,
          click: function (e) {
            e.preventDefault();
            changeMenu("admin");
          },
        },
        {
          id: "role",
          label: "Role",
          link: ROUTES.ROLE,
          parentId: "apps",
          isActive: false,
          permission: AllPermissions[29]?.name,
          click: function (e) {
            e.preventDefault();
            changeMenu("role");
          },
        },
      ],
    },
    {
      id: "employeeManagement",
      label: "Employee Management",
      icon: "ri-account-circle-line",
      link: "/#",
      isActive: false,
      isChildItem: true,
      role: "employeeRole",
      click: function (e) {
        e.preventDefault();
        changeMenu("employeeManagement");
      },
      subItems: [
        {
          id: "employee",
          label: "Employee",
          link: ROUTES.EMPLOYEE,
          parentId: "apps",
          isActive: false,
          permission: AllPermissions[6]?.name,
          click: function (e) {
            e.preventDefault();
            changeMenu("employee");
          },
        },
        {
          id: "leave",
          label: "Employee Leave",
          link: ROUTES.EMPLOYEE_LEAVE,
          parentId: "apps",
          isActive: false,
          permission: AllPermissions[24]?.name,
          role: "employeeRole",
          click: function (e) {
            e.preventDefault();
            changeMenu("leave");
          },
        },
        {
          id: "salary",
          label: "Salary Increment",
          link: ROUTES.SALARY_INCREMENT,
          parentId: "apps",
          isActive: false,
          permission: AllPermissions[13]?.name,
          click: function (e) {
            e.preventDefault();
            changeMenu("salary");
          },
        },
        {
          id: "emp_attendance",
          label: "Employee Attendance",
          link: ROUTES.EMPLOYEE_ATTENDANCE,
          parentId: "apps",
          isActive: false,
          role: "employeeRole",
          permission: AllPermissions[1]?.name,
          click: function (e) {
            e.preventDefault();
            changeMenu("emp_attendance");
          },
        },
        {
          id: "emp_salary",
          label: "Employee Salary",
          link: ROUTES.EMPLOYEE_SALARY,
          parentId: "apps",
          isActive: false,
          permission: AllPermissions[15]?.name,
          click: function (e) {
            e.preventDefault();
            changeMenu("emp_salary");
          },
        },
        {
          id: "deposit",
          label: "Employee Deposit",
          link: ROUTES.DEPOSIT,
          parentId: "apps",
          isActive: false,
          permission: AllPermissions[12]?.name,
          click: function (e) {
            e.preventDefault();
            changeMenu("deposit");
          },
        },
        {
          id: "overttime_approval",
          label: "Overtime Approval",
          link: ROUTES.OVER_TIME_APPROVAL,
          parentId: "apps",
          isActive: false,
          permission: AllPermissions[5]?.name,
          click: function (e) {
            e.preventDefault();
            changeMenu("overttime_approval");
          },
        },
      ],
    },

    {
      id: "bankManagment",
      label: "Bank Management",
      icon: " ri-bank-line",
      link: "/#",
      isActive: false,
      isChildItem: true,
      // role: "employeeRole",
      click: function (e) {
        e.preventDefault();
        changeMenu("bankManagment");
      },
      subItems: [
        {
          id: "banksId",
          label: "Bank Details",
          link: ROUTES.BANK_DETAILS,
          parentId: "apps",
          isActive: false,
          permission: AllPermissions[26]?.name,
          click: function (e) {
            e.preventDefault();
            changeMenu("banksId");
          },
        },
        {
          id: "banksIncome",
          label: "Income Statements",
          link: ROUTES.BANK_INCOMES,
          parentId: "apps",
          isActive: false,
          permission: AllPermissions[33]?.name,
          click: function (e) {
            e.preventDefault();
            changeMenu("banksIncome");
          },
        },
        {
          id: "salaryAnalysis",
          label: "Salary Analysis",
          link: ROUTES.SALARY_ANALYSIS,
          parentId: "apps",
          isActive: false,
          permission: AllPermissions[27]?.name,
          click: function (e) {
            e.preventDefault();
            changeMenu("salaryAnalysis");
          },
        },
        {
          id: "bankWiseEmpSalary",
          label: "Assign Employee",
          link: ROUTES.EMPLOYEE_BANK_WISE_SALARY,
          parentId: "apps",
          isActive: false,
          permission: AllPermissions[15]?.name,
          click: function (e) {
            e.preventDefault();
            changeMenu("bankWiseEmpSalary");
          },
        },
      ],
    },

    {
      id: "letterManagement",
      label: "Letter Management",
      icon: "ri-file-list-3-line",
      link: "/#",
      isActive: false,
      isChildItem: true,
      role: "employeeRole",
      click: function (e) {
        e.preventDefault();
        changeMenu("letterManagement");
      },
      subItems: [
        {
          id: "letterFormat",
          label: "Letter Format",
          // icon: "ri-file-list-3-line",
          link: ROUTES.LETTER_FORMAT,
          parentId: "apps",
          isActive: false,
          permission: AllPermissions[28]?.name,
          click: function (e) {
            e.preventDefault();
            changeMenu("letterFormat");
          },
        },
        {
          id: "createdLetters",
          label: "Generated Letter",
          // icon: " ri-file-edit-fill",
          link: ROUTES.CREATED_LETTER,
          parentId: "apps",
          isActive: false,
          permission: AllPermissions[28]?.name,
          click: function (e) {
            e.preventDefault();
            changeMenu("createdLetters");
          },
        },
      ],
    },

    // {
    //   id: "leaveManagement",
    //   label: "Leave Management",
    //   icon: "ri-calendar-2-line",
    //   link: "/#",
    //   isActive: false,
    //   isChildItem: true,
    //   click: function (e) {
    //     e.preventDefault();
    //     changeMenu("leaveManagement");
    //   },
    //   subItems: [
    {
      id: "leaveMaster",
      label: "Leave Master",
      icon: "ri-calendar-2-line",
      link: ROUTES.LEAVE_MASTER,
      parentId: "apps",
      isActive: false,
      permission: AllPermissions[2]?.name,
      click: function (e) {
        e.preventDefault();
        changeMenu("leaveMaster");
      },
    },
    // ],
    // },
    // {
    //   id: "calenderMaster",
    //   label: "Calendar Management",
    //   icon: "ri-calendar-event-fill",
    //   link: "/#",
    //   isActive: false,
    //   isChildItem: true,
    //   role: "employeeRole",
    //   click: function (e) {
    //     e.preventDefault();
    //     changeMenu("calenderMaster");
    //   },
    //   subItems: [
    {
      id: "calendar",
      label: "Calendar",
      icon: "ri-calendar-event-fill",
      link: ROUTES.CALENDAR,
      parentId: "apps",
      role: "employeeRole",
      isActive: false,
      permission: AllPermissions[18]?.name,
      click: function (e) {
        e.preventDefault();
        changeMenu("calendar");
      },
    },
    // ],
    // },
    {
      id: "hubstafActivities",
      label: "HubStaff Activities",
      icon: " ri-timer-line",
      link: "/#",
      isActive: false,
      isChildItem: true,
      role: "employeeRole",
      click: function (e) {
        e.preventDefault();
        changeMenu("hubstafActivities");
      },
      subItems: [
        {
          id: "activites",
          label: "Activities",
          link: ROUTES.HUBSTAFF_ACTIVITIES,
          parentId: "apps",
          role: "employeeRole",
          isActive: false,
          permission: AllPermissions[21]?.name,
          click: function (e) {
            e.preventDefault();
            changeMenu("activites");
          },
        },
        {
          id: "fetchRequest",
          label: "Fetch Request",
          link: ROUTES.HUBSTAFF_FETCH_REQUEST,
          parentId: "apps",
          isActive: false,
          permission: AllPermissions[21]?.name,
          click: function (e) {
            e.preventDefault();
            changeMenu("fetchRequest");
          },
        },
        {
          id: "urlActivites",
          label: "URL Activities",
          link: ROUTES.URL_ACTIVITIES,
          parentId: "apps",
          role: "employeeRole",
          isActive: false,
          permission: AllPermissions[21]?.name,
          click: function (e) {
            e.preventDefault();
            changeMenu("urlActivites");
          },
        },
        {
          id: "applicationActivites",
          label: "Application Activities",
          link: ROUTES.APPLICATION_ACTIVITY,
          parentId: "apps",
          isActive: false,
          permission: AllPermissions[21]?.name,
          role: "employeeRole",
          click: function (e) {
            e.preventDefault();
            changeMenu("applicationActivites");
          },
        },

        {
          id: "addTimeRequestHubstaff",
          label: "Add Time Request",
          link: ROUTES.HUBSTAFF_ADD_TIME_REQUEST,
          parentId: "apps",
          isActive: false,
          role: "employeeRole",
          permission: AllPermissions[34]?.name,
          click: function (e) {
            e.preventDefault();
            changeMenu("addTimeRequestHubstaff");
          },
        },
      ],
    },
    // {
    //   id: "punchesActivity",
    //   label: "Punches Activities",
    //   icon: "ri-fingerprint-line",
    //   link: "/#",
    //   isActive: false,
    //   isChildItem: true,
    //   role: "employeeRole",
    //   click: function (e) {
    //     e.preventDefault();
    //     changeMenu("punchesActivity");
    //   },
    //   subItems: [
    {
      id: "punch_activity",
      label: "Punch Activity",
      link: ROUTES.PUNCH_ACTIVITY,
      icon: "ri-fingerprint-line",
      parentId: "apps",
      role: "employeeRole",
      isActive: false,
      permission: AllPermissions[3]?.name,
      click: function (e) {
        e.preventDefault();
        changeMenu("punch_activity");
      },
    },
    // ],
    // },
    // {
    //   id: "reportSummary",
    //   label: "Report Summary",
    //   icon: "ri-todo-fill",
    //   link: "/#",
    //   isActive: false,
    //   isChildItem: true,
    //   role: "employeeRole",
    //   click: function (e) {
    //     e.preventDefault();
    //     changeMenu("reportSummary");
    //   },
    //   subItems: [
    {
      id: "reportSummary",
      label: "Report Summary",
      link: ROUTES.REPORT_SUMMARY,
      icon: "ri-todo-fill",
      parentId: "apps",
      role: "employeeRole",
      isActive: false,
      permission: AllPermissions[20]?.name,
      click: function (e) {
        e.preventDefault();
        changeMenu("reportSummary");
      },
    },
    // ],
    // },
  ]);
  const changeMenu = (menuId, field = "id", __menus = null) => {
    let _menus = [...(__menus == null ? menus : __menus)];

    _menus = _menus.map((menu) => {
      menu.isActive = menu[field] == menuId ? true : false;

      if (menu.hasOwnProperty("subItems")) {
        let isMainMenuActive = false;

        menu.subItems = menu.subItems.map((subItem) => {
          let isSubMenuActive = false;
          const spitPath = menuId.split("/");
          const slicePath = spitPath.slice(0, 2);
          const id = slicePath.join("/");

          if (subItem[field] == id) {
            isMainMenuActive = true;
          }
          subItem.isActive = subItem[field] === id ? true : false;

          if (subItem.hasOwnProperty("childItems")) {
            subItem.childItems = subItem.childItems.map((childItem) => {
              childItem.isActive =
                childItem[field] == menuId ? !childItem.isActive : false;
              if (childItem[field] == menuId) {
                isMainMenuActive = true;
                isSubMenuActive = true;
              }

              return childItem;
            });
          }

          if (isSubMenuActive) {
            subItem.isActive = isSubMenuActive;
          }

          return subItem;
        });

        if (isMainMenuActive) {
          menu.isActive = isMainMenuActive;
        }
        return menu;
      }

      return menu;
    });

    if (field == "id") {
      _menus = activateCurrentRoute(window.location.pathname, _menus);
    }

    setMenus([..._menus]);
  };

  const activateCurrentRoute = (menuId, ___menus) => {
    let _menus = [...___menus];

    _menus = _menus.map((menu) => {
      menu.isActive = menu["link"] == menuId ? true : menu.isActive;
      if (menu.hasOwnProperty("subItems")) {
        menu.subItems = menu.subItems.map((subItem) => {
          subItem.isActive =
            subItem["link"] == menuId ? true : subItem.isActive;

          if (subItem.hasOwnProperty("childItems")) {
            subItem.childItems = subItem.childItems.map((childItem) => {
              childItem.isActive =
                childItem["link"] == menuId ? true : childItem.isActive;
              return childItem;
            });
          }

          return subItem;
        });

        return menu;
      }

      return menu;
    });

    return _menus;
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const initMenu = () => {
      changeMenu(window.location.pathname, "link");
    };

    if (!HELPER.isEmpty(menus)) {
      initMenu();
    }
  }, [path]);

  const filteredMenus = menus
    .map((menu) => {
      if (auth?.role_name === "Employee") {
        if (menu.role !== "employeeRole") {
          return null;
        }

        if (menu.subItems) {
          menu.subItems = menu.subItems.filter((subItem) => {
            return subItem.role === "employeeRole";
          });

          if (menu.subItems.length === 0) {
            return null;
          }
        }
      } else {
        if (menu.permission) {
          const hasPermission = auth?.permissions.some(
            (permission) =>
              permission.name === menu.permission && permission.view === 1
          );

          if (!hasPermission) {
            return null;
          }
        }

        if (menu.subItems) {
          menu.subItems = menu.subItems.filter((subItem) => {
            const subItemPermission = auth?.permissions.find(
              (permission) => permission.name === subItem.permission
            );

            return !subItemPermission || subItemPermission.view === 1;
          });
          if (menu.subItems.length === 0) {
            return null;
          }
        }
      }
      return menu;
    })
    .filter(Boolean);

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
  }, [history]);

  return <React.Fragment>{filteredMenus}</React.Fragment>;
};
export default Navdata;
