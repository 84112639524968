const defaultState = {
    toolkit: {
        auth: {
            previousAuth: {
                token: null,
                user: null,
            },
            token: null,
            user: null,
        }
    }
}


export default defaultState;