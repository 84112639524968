import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import { ROUTES } from "../configs";
import { filterRoute } from "../configs/routes";

const Login = lazy(() => import("../pages/Authentication/Login"));
const DashboardEcommerce = lazy(() => import("../pages/DashboardEcommerce"));
const ForgetPasswordPage = lazy(() =>
  import("../pages/Ecommerce/ForgotPassword/ForgotPasswordPage")
);
const ResetPasswordPage = lazy(() =>
  import("../pages/Ecommerce/ForgotPassword/ResetPasswordPage")
);

const Employee = lazy(() => import("../pages/Ecommerce/employee/employee"));
const EmployeeDashboardPage = lazy(() =>
  import("../pages/Ecommerce/employeeDashbaord/EmployeeDashboardPage")
);

const ViewEmployeePage = lazy(() =>
  import("../pages/Ecommerce/employee/ViewEmployeePage")
);
const EmployeeDetailsPage = lazy(() =>
  import("../pages/Ecommerce/employee/EmployeeDetailsPage")
);
const EditEmployeeDetailsPage = lazy(() =>
  import("../pages/Ecommerce/employee/EditEmployeeDetailsPage")
);
// const EditEmployeePage = lazy(() =>
//   import("../pages/Ecommerce/employee/EditEmployeePage")
// );
// ---------------- User Management ------------------
const UserPage = lazy(() => import("../pages/Ecommerce/UserDetails/UserPage"));

const Role = lazy(() => import("../pages/Ecommerce/rolePermission/Role"));
const RolePageDetails = lazy(() =>
  import("../pages/Ecommerce/rolePermission/RolePageDetails")
);
// const EditRolePage = lazy(() =>
//   import("../pages/Ecommerce/rolePermission/EditRolePage")
// );
const LeaveMasterPage = lazy(() =>
  import("../pages/Ecommerce/leave/LeaveMasterPage")
);
const EmployeeLeavePage = lazy(() =>
  import("../pages/Ecommerce/employee/EmployeeLeavePage")
);
const SalaryIncrement = lazy(() =>
  import("../pages/Ecommerce/salaryIncrement/SalaryIncrement")
);
const Calendar = lazy(() => import("../pages/Ecommerce/calendar/calender"));
const AppSetting = lazy(() =>
  import("../pages/Ecommerce/appSetting/AppSetting")
);
const CompanyDetailsPage = lazy(() =>
  import("../pages/CompanyDetails/CompanyDetailsPage")
);
const RulesAndRegulationsPage = lazy(() =>
  import("../pages/RulesAndRegulations/RulesAndRegulationsPage")
);
const HubstaffActivitiesPage = lazy(() =>
  import("../pages/HubStaff/Activities/HubstaffActivities")
);
const HubstaffFetchRequestPage = lazy(() =>
  import("../pages/HubStaff/Activities/HubstaffFetchRequestPage")
);
const HubstaffAddTimeRequestPage = lazy(() =>
  import("../pages/HubStaff/AddTimeRequest/HubstaffAddTimeRequestPage")
);
const ApplicationActivity = lazy(() =>
  import("../pages/HubStaff/ApplicationActivites/ApplicationActivities")
);
const UrlActivitesPage = lazy(() =>
  import("../pages/HubStaff/UrlActivites/UrlActivities")
);

const ViewIdleTime = lazy(() =>
  import("../pages/HubStaff/Activities/ViewIdleTime")
);
const Projects = lazy(() => import("../pages/HubStaff/Projects/Projects"));
const RefreshToken = lazy(() =>
  import("../pages/Ecommerce/refreshToken/RefreshToken")
);

const PunchesActivity = lazy(() =>
  import("../pages/Ecommerce/punches/PunchesActivity")
);

const EmployeeAttendance = lazy(() =>
  import("../pages/Ecommerce/employee/EmployeeAttendance")
);

const ReportSummaryPage = lazy(() =>
  import("../pages/Ecommerce/EmployeeReport/ReportSummaryPage")
);

const EmployeeSalary = lazy(() =>
  import("../pages/Ecommerce/employee/EmployeeSalary")
);

const Deposit = lazy(() => import("../pages/Ecommerce/deposit/deposit"));

const OverTimeApprovalPage = lazy(() =>
  import("../pages/Ecommerce/overtimeApproval/OverTimeApproval")
);
const BankDetailsPage = lazy(() =>
  import("../pages/Ecommerce/bankManagement/BankDetailsPage")
);
const BankIncomeStatementPage = lazy(() =>
  import("../pages/Ecommerce/bankIncomeStatement/BankIncomeStatementPage")
);
const SalaryAnalysisPage = lazy(() =>
  import("../pages/Ecommerce/salaryAnalysis/SalaryAnalysisPage")
);
const BankWiseEmployeeSalaryPage = lazy(() =>
  import("../pages/Ecommerce/bankWiseEmployeSalary/BankWiseEmployeeSalaryPage")
);
const LetterFormatPage = lazy(() =>
  import("../pages/Ecommerce/letterFormat/LetterFormatPage")
);
const CreatedLetterPage = lazy(() =>
  import("../pages/Ecommerce/CreatedLetter/CreatedLetterPage")
);
const MettingDashboardPage = lazy(() =>
  import("../pages/Ecommerce/MettingDashboard/MettingDashboardPage")
);
const ProjectSingleViewPage = lazy(() =>
  import("../pages/HubStaff/Projects/ProjectSingleViewPage")
);
const TaskManagmentPage = lazy(() =>
  import("../pages/Ecommerce/TaskManagment/TaskManagmentPage")
);
const TaskMangmentSingleViewPage = lazy(() =>
  import("../pages/Ecommerce/TaskManagment/TaskMangmentSingleViewPage")
);
const TaskManagementBoardPage = lazy(() =>
  import("../pages/Ecommerce/TaskBoard/TaskManagementBoardPage")
);
const CurrencyMasterPage = lazy(() =>
  import("../pages/Ecommerce/CurrencyMaster/CurrencyMasterPage")
);
const AnalysisDashboardIndexPage = lazy(() =>
  import("../pages/Ecommerce/AnalysisDashboard")
);

const authProtectedRoutes = [
  { path: filterRoute(ROUTES.DASHBOARD), component: <DashboardEcommerce /> },
  {
    path: filterRoute(ROUTES.EMPLOYEE_DASHBOARD),
    component: <EmployeeDashboardPage />,
  },
  {
    path: filterRoute(ROUTES.MEETING_DASHBOARD),
    component: <MettingDashboardPage />,
  },
  {
    path: "/",
    exact: true,
    component: <Navigate to={filterRoute(ROUTES.DASHBOARD)} />,
  },
  { path: "*", component: <Navigate to={filterRoute(ROUTES.DASHBOARD)} /> },
  { path: "/employee", component: <Employee /> },
  // { path: "/role-permision", component: <RolePermision /> },
  { path: "/calendar", component: <Calendar /> },

  // { path: "*", component: <Navigate to={filterRoute(ROUTES.DASHBOARD)} /> },
  { path: filterRoute(ROUTES.ROLE), component: <Role /> },
  { path: filterRoute(ROUTES.ADD_ROLE), component: <RolePageDetails /> },
  {
    path: `${filterRoute(ROUTES.EDIT_ROLE)}/:id`,
    component: <RolePageDetails />,
  },
  { path: filterRoute(ROUTES.LEAVE_MASTER), component: <LeaveMasterPage /> },
  { path: `${filterRoute(ROUTES.EMPLOYEE)}`, component: <Employee /> },
  {
    path: `${filterRoute(ROUTES.EMPLOYEE_ATTENDANCE)}`,
    component: <EmployeeAttendance />,
  },
  {
    path: `${filterRoute(ROUTES.EMPLOYEE_SALARY)}`,
    component: <EmployeeSalary />,
  },
  {
    path: `${filterRoute(ROUTES.EMPLOYEE_LEAVE)}`,
    component: <EmployeeLeavePage />,
  },

  {
    path: `${filterRoute(ROUTES.SALARY_INCREMENT)}`,
    component: <SalaryIncrement />,
  },
  {
    path: `${filterRoute(ROUTES.ADD_EMPLOYEE)}`,
    component: <EmployeeDetailsPage />,
  },
  {
    path: `${filterRoute(ROUTES.EDIT_EMPLOYEE)}/:id`,
    component: <EditEmployeeDetailsPage />,
  },
  {
    path: `${filterRoute(ROUTES.VIEW_EMPLOYEE)}/:id`,
    component: <ViewEmployeePage />,
  },
  {
    path: `${filterRoute(ROUTES.VIEW_EMPLOYEE)}`,
    component: <ViewEmployeePage />,
  },
  // ---------------- User Management ------------------
  {
    path: `${filterRoute(ROUTES.USERS)}`,
    component: <UserPage />,
  },

  {
    path: filterRoute(ROUTES.APP_SETTING),
    component: <AppSetting />,
  },
  {
    path: filterRoute(ROUTES.COMPANY_DETAILS),
    component: <CompanyDetailsPage />,
  },
  {
    path: filterRoute(ROUTES.RULES_AND_REGULATIONS),
    component: <RulesAndRegulationsPage />,
  },
  {
    path: filterRoute(ROUTES.APPLICATION_ACTIVITY),
    component: <ApplicationActivity />,
  },
  {
    path: filterRoute(ROUTES.HUBSTAFF_ACTIVITIES),
    component: <HubstaffActivitiesPage />,
  },
  {
    path: filterRoute(ROUTES.HUBSTAFF_FETCH_REQUEST),
    component: <HubstaffFetchRequestPage />,
  },
  {
    path: filterRoute(ROUTES.URL_ACTIVITIES),
    component: <UrlActivitesPage />,
  },
  {
    path: filterRoute(ROUTES.VIEW_IDLE_TIME),
    component: <ViewIdleTime />,
  },
  {
    path: filterRoute(ROUTES.PROJECTS),
    component: <Projects />,
  },
  {
    path: filterRoute(ROUTES.REFRESH_TOKEN),
    component: <RefreshToken />,
  },
  {
    path: filterRoute(ROUTES.PUNCH_ACTIVITY),
    component: <PunchesActivity />,
  },
  {
    path: `${filterRoute(ROUTES.REPORT_SUMMARY)}`,
    component: <ReportSummaryPage />,
  },
  {
    path: `${filterRoute(ROUTES.DEPOSIT)}`,
    component: <Deposit />,
  },
  {
    path: `${filterRoute(ROUTES.OVER_TIME_APPROVAL)}`,
    component: <OverTimeApprovalPage />,
  },
  {
    path: `${filterRoute(ROUTES.BANK_DETAILS)}`,
    component: <BankDetailsPage />,
  },
  {
    path: `${filterRoute(ROUTES.BANK_INCOMES)}`,
    component: <BankIncomeStatementPage />,
  },
  {
    path: `${filterRoute(ROUTES.SALARY_ANALYSIS)}`,
    component: <SalaryAnalysisPage />,
  },
  {
    path: `${filterRoute(ROUTES.EMPLOYEE_BANK_WISE_SALARY)}`,
    component: <BankWiseEmployeeSalaryPage />,
  },
  {
    path: `${filterRoute(ROUTES.LETTER_FORMAT)}`,
    component: <LetterFormatPage />,
  },
  {
    path: `${filterRoute(ROUTES.CREATED_LETTER)}`,
    component: <CreatedLetterPage />,
  },
  {
    path: `${filterRoute(ROUTES.HUBSTAFF_ADD_TIME_REQUEST)}`,
    component: <HubstaffAddTimeRequestPage />,
  },
  {
    path: `${filterRoute(ROUTES.PROJECT_SINGLE_VIEW)}`,
    component: <ProjectSingleViewPage />,
  },
  {
    path: `${filterRoute(ROUTES.TASK_MANAGMENT)}`,
    component: <TaskManagmentPage />,
  },
  {
    path: `${filterRoute(ROUTES.TASK_MANAGMENT_SINGLE_VIEW)}`,
    component: <TaskMangmentSingleViewPage />,
  },
  {
    path: `${filterRoute(ROUTES.TASK_BOARD)}`,
    component: <TaskManagementBoardPage />,
  },
  {
    path: `${filterRoute(ROUTES.CURRENCY_MASTER)}`,
    component: <CurrencyMasterPage />,
  },
  {
    path: `${filterRoute(ROUTES.ANALYSIS_DASHBOARD)}`,
    component: <AnalysisDashboardIndexPage />,
  },
];

const employeerotectedRoutes = [
  {
    path: filterRoute(ROUTES.VIEW_EMPLOYEE),
    component: <ViewEmployeePage />,
  },
  {
    path: "/",
    exact: true,
    component: <Navigate to={filterRoute(ROUTES.VIEW_EMPLOYEE)} />,
  },
  { path: "*", component: <Navigate to={filterRoute(ROUTES.VIEW_EMPLOYEE)} /> },
  { path: "/calendar", component: <Calendar /> },
  {
    path: `${filterRoute(ROUTES.EMPLOYEE_LEAVE)}`,
    component: <EmployeeLeavePage />,
  },
  {
    path: `${filterRoute(ROUTES.VIEW_EMPLOYEE)}`,
    component: <ViewEmployeePage />,
  },

  {
    path: filterRoute(ROUTES.APPLICATION_ACTIVITY),
    component: <ApplicationActivity />,
  },
  {
    path: filterRoute(ROUTES.HUBSTAFF_ACTIVITIES),
    component: <HubstaffActivitiesPage />,
  },
  {
    path: filterRoute(ROUTES.URL_ACTIVITIES),
    component: <UrlActivitesPage />,
  },
  {
    path: filterRoute(ROUTES.VIEW_IDLE_TIME),
    component: <ViewIdleTime />,
  },
  {
    path: filterRoute(ROUTES.PUNCH_ACTIVITY),
    component: <PunchesActivity />,
  },
  {
    path: `${filterRoute(ROUTES.EMPLOYEE_ATTENDANCE)}`,
    component: <EmployeeAttendance />,
  },
  {
    path: `${filterRoute(ROUTES.REPORT_SUMMARY)}`,
    component: <ReportSummaryPage />,
  },
  {
    path: filterRoute(ROUTES.RULES_AND_REGULATIONS),
    component: <RulesAndRegulationsPage />,
  },
  {
    path: `${filterRoute(ROUTES.HUBSTAFF_ADD_TIME_REQUEST)}`,
    component: <HubstaffAddTimeRequestPage />,
  },
  {
    path: `${filterRoute(ROUTES.TASK_MANAGMENT)}`,
    component: <TaskManagmentPage />,
  },
  {
    path: `${filterRoute(ROUTES.TASK_MANAGMENT_SINGLE_VIEW)}`,
    component: <TaskMangmentSingleViewPage />,
  },
  {
    path: `${filterRoute(ROUTES.TASK_BOARD)}`,
    component: <TaskManagementBoardPage />,
  },
  {
    path: filterRoute(ROUTES.PROJECTS),
    component: <Projects />,
  },
  {
    path: `${filterRoute(ROUTES.PROJECT_SINGLE_VIEW)}`,
    component: <ProjectSingleViewPage />,
  },
];

const publicRoutes = [
  { path: filterRoute(ROUTES.LOGIN), component: <Login /> },
  {
    path: filterRoute(ROUTES.FORGOT_PASSWORD),
    component: <ForgetPasswordPage />,
  },
  {
    path: `${filterRoute(ROUTES.RESET_PASSWORD)}/:code`,
    component: <ResetPasswordPage />,
  },
];

export { authProtectedRoutes, publicRoutes, employeerotectedRoutes };
