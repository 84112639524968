import React, { useState } from "react";
import { Form, Modal, ModalBody, ModalHeader } from "reactstrap";
import Textinput from "../../Components/Common/Textinput";
import ReactButton from "../../Components/Common/ReactButton";
import Validators from "../../Components/validations/Validator";
import { API, HELPER } from "../../services";
import { apiConfig } from "../../configs";

const ChangeSecurityPincodeModal = ({ modal, toggle, setModal }) => {
  const [isLoader, setIsLoader] = useState(false);
  //  -------------formState --------------
  const [formState, setFormState] = useState({
    old_secret_pin: "",
    new_secret_pin: "",
  });
  //  -------------Validation --------------
  const rules = {
    old_secret_pin: "required",
    new_secret_pin: "required",
  };
  //  -------------handle Change Pin --------------
  const handleSubmit = (data) => {
    setIsLoader(true);
    API.post(apiConfig.changePinOldPin, data)
      .then((res) => {
        HELPER.toaster.success(res?.message);
        setModal(false);
      })
      .catch(HELPER.showErrorToaster)
      .finally(() => {
        setIsLoader(false);
      });
  };
  // -----------------onChange--------------------
  // const onChange = ({ target: { value, name } }) => {
  //   setFormState((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };
  const onChange = ({ target: { value, name } }) => {
    if (
      (name === "old_secret_pin" && value.length > 4) ||
      (name === "new_secret_pin" && value.length > 4)
    ) {
      return;
    }
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    // -------------------Change Pin Modal---------------------
    <div>
      <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Change Pin
        </ModalHeader>
        <ModalBody>
          <Validators formData={formState} rules={rules}>
            {({ onSubmit, errors }) => {
              return (
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit(handleSubmit);
                  }}
                  action="#"
                >
                  <Textinput
                    label={"Old Pin"}
                    value={formState.old_secret_pin}
                    name="old_secret_pin"
                    placeholder="Enter Old Pin"
                    error={errors?.old_secret_pin}
                    type="password"
                    onChange={onChange}
                  />
                  <Textinput
                    label={"New Pin"}
                    value={formState.new_secret_pin}
                    name="new_secret_pin"
                    placeholder="Enter New Pin"
                    error={errors?.new_secret_pin}
                    type="password"
                    onChange={onChange}
                  />
                  <div className="mt-4">
                    <ReactButton
                      loader={isLoader}
                      color="success"
                      className="btn btn-success w-100"
                      type="submit"
                    >
                      Submit
                    </ReactButton>
                  </div>
                </Form>
              );
            }}
          </Validators>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ChangeSecurityPincodeModal;
