import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { useSelector } from "react-redux";
import { ROUTES, apiConfig } from "../../../configs";
import { API, HELPER } from "../../../services";
import { taskManagementStatus } from "../../../services/enums";
import { SmartWatch } from "../../../services/helper";
import CurrentTaskStatusModal from "../../../pages/Ecommerce/TaskManagment/CurrentTaskStatusModal";
import { handleCurrentTaskFlag } from "../../../slices/toolkit/auth";
import { store } from "../../../slices";

const CurrentTaskSideBarCard = () => {
  const auth = useSelector((state) => state?.auth?.user);
  const location = useLocation();
  const navigate = useNavigate();
  const currentTaskFlag = useSelector((state) => state.auth.currentTaskFlag);

  //  -------------------- Current Task States ----------------------
  const [storeCurrentTaskData, setStoreCurrentTaskData] = useState([]);
  const [noteModal, setNoteModal] = useState(false);
  const [statusNote, setStatusNote] = useState({
    notes: "",
  });
  // -------------Get Current Task ------------------
  const getcurrentTaskSlider = () => {
    API.get(apiConfig.currentTaskSlider)
      .then((res) => {
        setStoreCurrentTaskData(res);
        store.dispatch(handleCurrentTaskFlag(false));
      })
      .catch(HELPER.showErrorToaster);
  };

  useEffect(() => {
    if (auth?.role_name === "Employee" && !HELPER.isEmpty(currentTaskFlag)) {
      getcurrentTaskSlider();
    }
  }, [currentTaskFlag]);

  // --------------- Handle Status Change ----------------------
  const taskStatusChangeAPI = (status) => {
    API.put(apiConfig.taskIdStatus.replace(":id", storeCurrentTaskData?.id), {
      status: status,
      notes: statusNote?.notes,
    })
      .then((res) => {
        HELPER.toaster.success("Task status updated");
        getcurrentTaskSlider();
        setNoteModal(false);
        setStatusNote({
          notes: "",
        });
      })
      .catch(HELPER.showErrorToaster);
  };

  //  ------------ handle View Page Navigate ------------
  const handleViewPageNavigate = (taskId) => {
    let viewTaskManagmentData = {
      id: taskId,
    };
    const queryString = new URLSearchParams(viewTaskManagmentData).toString();

    window.open(`${ROUTES.TASK_MANAGMENT_SINGLE_VIEW}?${queryString}`);
  };
  return (
    <div>
      {/* --------------- Current Task Card ---------------- */}
      {auth?.role_name === "Employee" &&
        ROUTES.TASK_BOARD !== location?.pathname &&
        ROUTES.TASK_MANAGMENT_SINGLE_VIEW !== location?.pathname &&
        ROUTES.TASK_MANAGMENT !== location?.pathname && (
          <Card className="sidebar-task-card-wrapper ">
            {!HELPER?.isEmpty(storeCurrentTaskData) ? (
              <CardBody className="d-flex flex-column align-items-center position-relative overflow-hidden ">
                <div
                  className="redirectio-icon cursor-pointer"
                  onClick={() => {
                    handleViewPageNavigate(storeCurrentTaskData?.id);
                  }}
                  title="Task Details"
                >
                  <i className="align-middle bx bx-link-external"></i>
                </div>
                <div className="d-flex flex-column align-items-center  mt-4">
                  <span
                    className=" task-title three-dot-text-task-title search-btn-width text-center"
                    title="Task Title"
                  >
                    {storeCurrentTaskData?.title}
                  </span>
                  <span
                    className="fs-14 fw-medium text-muted three-dot-text-task-title search-btn-width text-center"
                    title="Project Name"
                  >
                    {storeCurrentTaskData?.project_name}{" "}
                  </span>
                  <span
                    className="text-danger fs-14 fw-medium mt-1"
                    title="Estimated Time"
                  >
                    ({storeCurrentTaskData?.estimated_time})
                  </span>
                </div>
                <div className="sidebar-task-start-stop">
                  {storeCurrentTaskData?.status ==
                  taskManagementStatus[1].value ? (
                    <div onClick={() => setNoteModal(true)} title="Stop">
                      <i className="ri-stop-circle-line align-bottom me-1  text-danger"></i>
                    </div>
                  ) : (
                    <div
                      onClick={() =>
                        taskStatusChangeAPI(taskManagementStatus[1].value)
                      }
                      title="Start"
                    >
                      <i className="ri-play-circle-line align-bottom me-1  text-success"></i>
                    </div>
                  )}
                </div>
                <div className="task-board-container">
                  <div
                    className="ribbon-three ribbon-three-info ribbon-three-common ribbon-three-common-after-none"
                    title="Work Start Time"
                  >
                    {storeCurrentTaskData?.status ===
                    taskManagementStatus[1]?.value ? (
                      <span title="Working Time">
                        <SmartWatch
                          startTime={storeCurrentTaskData?.work_start_time}
                        />
                      </span>
                    ) : (
                      <span title="Total Time Spend">
                        {storeCurrentTaskData?.total_time_spend}
                      </span>
                    )}
                  </div>
                </div>
              </CardBody>
            ) : (
              <CardBody className="d-flex flex-column justify-content-center align-items-center no-task-card-wraper">
                <i
                  className="align-middle ri-survey-line cursor-pointer"
                  onClick={() => navigate(ROUTES.TASK_BOARD)}
                ></i>
                <p className="mb-0">Please Select Any Task</p>
              </CardBody>
            )}
          </Card>
        )}

      {auth?.role_name === "Employee" && noteModal && (
        <CurrentTaskStatusModal
          noteModal={noteModal}
          setNoteModal={setNoteModal}
          setStatusNote={setStatusNote}
          statusNote={statusNote}
          taskStatusChangeAPI={taskStatusChangeAPI}
        />
      )}
    </div>
  );
};

export default CurrentTaskSideBarCard;
