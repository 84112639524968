import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

//import images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import { store } from "../../slices";
import { handleLogout } from "../../slices/toolkit/auth";
import { useSelector } from "react-redux";
import { ROUTES } from "../../configs";
import ChangePassword from "../../pages/ChangePassword/ChangePassword";
import { HELPER } from "../../services";
import ChangeSecurityPincodeModal from "../../pages/ChangePassword/ChangeSecurityPincodeModal";
import { AllPermissions } from "../../services/enums";

const ProfileDropdown = () => {
  //  ---------------------- auth ------------------
  const auth = useSelector((state) => state.auth);
  //  ---------------------- userPermissions ---------------------
  const userPermissions = useSelector(
    (state) => state?.auth?.user?.permissions
  );
  const refreshTokenPermision = userPermissions?.find(
    (item) => item?.name === AllPermissions[4]?.name
  );
  const rulesPermision = userPermissions?.find(
    (item) => item?.name === AllPermissions[14]?.name
  );
  const appSettingPermision = userPermissions?.find(
    (item) => item?.name === AllPermissions[25]?.name
  );
  const companyDetailsPermision = userPermissions?.find(
    (item) => item?.name === AllPermissions[30]?.name
  );
  const currencyMasterPermision = userPermissions?.find(
    (item) => item?.name === AllPermissions[36]?.name
  );

  //  ----------------------- states -------------------------
  const navigate = useNavigate();
  const [isChangePass, setChangePass] = useState(false);
  const [isChangePin, setChangePin] = useState(false);

  // --------------------------- PassToggle -----------
  const PassToggle = useCallback(() => {
    setChangePass(false);
  }, [isChangePass]);

  // -------------- ChangePinToggle ---------
  const ChangePinToggle = useCallback(() => {
    setChangePin(false);
  }, [isChangePin]);

  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center">
            <img
              className="rounded-circle header-profile-user"
              src={HELPER.getProfileImage(
                auth?.user?.profile,
                auth?.user?.first_name,
                auth?.user?.last_name
              )}
              onError={(e) =>
                HELPER.handleImageOnError(
                  e,
                  auth?.user?.first_name,
                  auth?.user?.last_name
                )
              }
              alt="Header Avatar"
            />
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {auth?.user?.first_name} {auth?.user?.last_name}
              </span>
              <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                {" "}
                {auth?.user?.role_name}
              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">
            Welcome {auth?.user?.first_name} !
          </h6>
          {auth?.user?.role_name === "Employee" && (
            <DropdownItem className="p-0">
              <div
                className="dropdown-item"
                onClick={() => navigate(ROUTES.VIEW_EMPLOYEE)}
              >
                <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>{" "}
                <span className="align-middle" data-key="t-logout">
                  Profile
                </span>
              </div>
            </DropdownItem>
          )}
          {companyDetailsPermision?.view === 1 && (
            <DropdownItem className="p-0">
              <div
                className="dropdown-item"
                onClick={() => navigate(ROUTES.COMPANY_DETAILS)}
              >
                <i className=" ri-building-4-fill text-muted fs-16 align-middle me-1"></i>{" "}
                <span className="align-middle" data-key="t-logout">
                  Company Details
                </span>
              </div>
            </DropdownItem>
          )}
          {currencyMasterPermision?.view === 1 && (
            <DropdownItem className="p-0">
              <div
                className="dropdown-item"
                onClick={() => navigate(ROUTES.CURRENCY_MASTER)}
              >
                <i className=" ri-money-dollar-box-line text-muted fs-16 align-middle me-1"></i>{" "}
                <span className="align-middle" data-key="t-logout">
                  Currency Master
                </span>
              </div>
            </DropdownItem>
          )}

          {(auth?.user?.role_name == "Employee" ||
            auth?.user?.role_name == "Admin") && (
            <DropdownItem className="p-0">
              <div
                className="dropdown-item"
                onClick={() => navigate(ROUTES.RULES_AND_REGULATIONS)}
              >
                <i className="ri-auction-line text-muted fs-16 align-middle me-1"></i>{" "}
                <span className="align-middle" data-key="t-logout">
                  Rules And Regulations
                </span>
              </div>
            </DropdownItem>
          )}
          {auth?.user?.role_name == "Staff" && rulesPermision?.view === 1 && (
            <DropdownItem className="p-0">
              <div
                className="dropdown-item"
                onClick={() => navigate(ROUTES.RULES_AND_REGULATIONS)}
              >
                <i className="ri-auction-line text-muted fs-16 align-middle me-1"></i>{" "}
                <span className="align-middle" data-key="t-logout">
                  Rules And Regulations
                </span>
              </div>
            </DropdownItem>
          )}
          {appSettingPermision?.view === 1 && (
            <DropdownItem className="p-0">
              <div
                className="dropdown-item"
                onClick={() => navigate(ROUTES.APP_SETTING)}
              >
                <i className="mdi  mdi-application-cog-outline text-muted fs-16 align-middle me-1"></i>{" "}
                <span className="align-middle" data-key="t-logout">
                  App Setting
                </span>
              </div>
            </DropdownItem>
          )}
          {auth?.user?.role_name !== "Employee" &&
            refreshTokenPermision?.view === 1 && (
              <DropdownItem
                className="p-0"
                onClick={() => navigate(ROUTES.REFRESH_TOKEN)}
              >
                <Link className="dropdown-item">
                  {/* <i className="ri-refresh-line"></i> */}
                  {/* <span class="ri--token-swap-fill"></span>  <span class=""></span>*/}
                  <i className="ri-refresh-line text-muted fs-16 align-middle me-1"></i>{" "}
                  <span className="align-middle" data-key="t-logout">
                    Refresh Token
                  </span>
                </Link>
              </DropdownItem>
            )}

          <DropdownItem className="p-0">
            <div
              // to={process.env.PUBLIC_URL + ROUTES.PROFILE}
              className="dropdown-item"
              onClick={() => {
                setChangePass(true);
              }}
            >
              <i className="ri-lock-password-line text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle">Change Password</span>
            </div>
          </DropdownItem>
          {auth?.user?.role_name === "Employee" && (
            <DropdownItem className="p-0">
              <div
                className="dropdown-item"
                onClick={() => {
                  setChangePin(true);
                }}
              >
                <i className=" ri-key-2-line text-muted fs-16 align-middle me-1"></i>
                <span className="align-middle">Change Pin</span>
              </div>
            </DropdownItem>
          )}

          <DropdownItem className="p-0">
            <Link
              className="dropdown-item"
              onClick={() => store.dispatch(handleLogout())}
            >
              <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
              <span className="align-middle" data-key="t-logout">
                Logout
              </span>
            </Link>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      {/* Change Password Component */}
      {isChangePass && (
        <ChangePassword
          modal={isChangePass}
          setModal={setChangePass}
          toggle={PassToggle}
          // callBack={() => paginate(true)}
        />
      )}

      {isChangePin && (
        <ChangeSecurityPincodeModal
          modal={isChangePin}
          setModal={setChangePin}
          toggle={ChangePinToggle}
          // callBack={() => paginate(true)}
        />
      )}
    </React.Fragment>
  );
};

export default ProfileDropdown;
