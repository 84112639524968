import React from "react";
import { Toaster } from "react-hot-toast";

//import Scss
import "./assets/scss/themes.scss";
import "./assets/scss/common.scss";

//imoprt Route
import Route from "./Routes";


function App() {
  
  return (
    <React.Fragment>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <Route />
    </React.Fragment>
  );
}

export default App;
