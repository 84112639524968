import React, { useEffect, useRef } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { taskManagementStatus } from "../../../services/enums";
import { HELPER } from "../../../services";

const CurrentTaskStatusModal = ({
  noteModal,
  setNoteModal,
  setStatusNote,
  statusNote,
  taskStatusChangeAPI,
}) => {
  const textareaRef = useRef(null);

  const focusTextarea = () => {
    textareaRef?.current?.focus();
  };

  //  ---------- Foucs Textarea ------------
  useEffect(() => {
    setTimeout(() => {
      focusTextarea();
    }, 10);
  }, []);

  return (
    <div>
      <Modal id="showModals29" isOpen={noteModal} centered className="modal-md">
        <ModalHeader
          className="bg-light p-3"
          toggle={() => setNoteModal(false)}
        >
          Status Note
        </ModalHeader>
        <ModalBody>
          <div className="">
            <div className="mb-3">
              <textarea
                className="form-control"
                id="VertimeassageInput45"
                rows="3"
                name="notes"
                value={statusNote?.notes}
                onChange={(e) =>
                  setStatusNote((prev) => ({
                    ...prev,
                    notes: e.target.value,
                  }))
                }
                placeholder="Enter Status Note"
                ref={textareaRef}
              ></textarea>
              <div className="text-end  col-6 col-sm-2 float-end mt-3">
                <button
                  color="success"
                  className="btn btn-success w-100"
                  onClick={() => {
                    taskStatusChangeAPI(taskManagementStatus[2].value);
                  }}
                  // disabled={HELPER.isEmpty(statusNote?.notes) ? true : false}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CurrentTaskStatusModal;
