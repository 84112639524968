import React, { Suspense } from "react";
import { Navigate, Route } from "react-router-dom";
import { HELPER } from "../services";
import PageLoader from "../Components/Common/PageLoader";
import { ROUTES } from "../configs";
import { filterRoute } from "../configs/routes";

const AuthProtected = (props) => {
  if (false == HELPER.isAuth()) {
    return (
      <Navigate to={{ pathname: filterRoute(ROUTES.LOGIN), state: { from: props.location } }} />
    );
  }

  return <Suspense fallback={<PageLoader />}>{props.children}</Suspense>;
};


const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (
          <Component {...props} />
        )
      }}
    />
  );
};

export { AuthProtected, AccessRoute };