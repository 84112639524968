// import React from 'react';
// import { Spinner } from 'reactstrap';

// const PageLoader = () => {
//     return (
//         <React.Fragment>
//             <div className="d-flex justify-content-center mx-2 mt-2">
//                 <Spinner color="primary"> Loading... </Spinner>
//             </div>
//         </React.Fragment>
//     );
// };

// export default PageLoader;

import React from "react";
import LoaderGif from "../../assets/images/loading.gif";
const PageLoader = () => {
  return (
    <React.Fragment>
      <div className="d-flex align-items-center justify-content-center page-loader-height">
        <img src={LoaderGif} alt="Logo" className="loader-git-img" />
      </div>
    </React.Fragment>
  );
};

export default PageLoader;
