/*------------------ Common Routes ------------------*/
export const LOGIN = "/login";
export const FORGOT_PASSWORD = "/forgot-password";
export const RESET_PASSWORD = "/reset-password";
// export const FORGOT_PASSWORD = "/forgot-password";
export const DASHBOARD = "/dashboard";
// export const STAFF_DASHBOARD = "/staff-dashboard";

//Employee Page
export const USERS = "/user";
export const EMPLOYEE = "/employee";
export const EMPLOYEE_DASHBOARD = "/employee-dashboard";
export const CALENDAR = "/calendar";
export const ROLE_PERMISSION = "/role-permision/:role_id";
export const ROLE = "/role";
export const ADD_ROLE = "/role/create";
export const EDIT_ROLE = "/role/edit";
export const LEAVE_MASTER = "/leave-master";
export const EMPLOYEE_LEAVE = "/employee-leave";
export const SALARY_INCREMENT = "/salary-increment";
export const EMPLOYEE_ATTENDANCE = "/employee-attendance";
export const EMPLOYEE_SALARY = "/employee-salary";
export const REPORT_SUMMARY = "/report-summary";
export const MEETING_DASHBOARD = "/meeting-dashboard";
export const PROJECT_SINGLE_VIEW = "/project-details-view";
export const TASK_MANAGMENT = "/task-management";
export const TASK_MANAGMENT_SINGLE_VIEW = "/task-details-view";
export const TASK_BOARD = "/task-board";
export const CURRENCY_MASTER = "/currency-master";
export const ANALYSIS_DASHBOARD = "/analysis-dashboard";

export const ADD_EMPLOYEE = "/employee/create";
export const EDIT_EMPLOYEE = "/employee/edit";
export const VIEW_EMPLOYEE = "/employee/view";
export const APP_SETTING = "/app-setting";
export const APPLICATION_ACTIVITY = "/application-activities";
export const HUBSTAFF_ACTIVITIES = "/activities";
export const HUBSTAFF_FETCH_REQUEST = "/hubstaff-fetch-request";
export const HUBSTAFF_ADD_TIME_REQUEST = "/add-time-request";
export const URL_ACTIVITIES = "/url-activities";
export const VIEW_IDLE_TIME = "/activities/view-idle-time";
export const PROJECTS = "/project-dashboard";
export const REFRESH_TOKEN = "/refresh-token";
export const PUNCH_ACTIVITY = "/punch-activity";
export const DEPOSIT = "/deposit";
export const OVER_TIME_APPROVAL = "/overtime-approval";
export const BANK_DETAILS = "/bank-details";
export const BANK_INCOMES = "/bank-incomes";
export const SALARY_ANALYSIS = "/salary-analysis";
export const EMPLOYEE_BANK_WISE_SALARY = "/bank-wise-employee-salary";
export const LETTER_FORMAT = "/letter-format";
export const CREATED_LETTER = "/generated-letter";
export const COMPANY_DETAILS = "/company-details";
export const RULES_AND_REGULATIONS = "/rules-and-regulations";

export const filterRoute = (route) => {
  return route.substring(1);
};
