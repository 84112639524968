import React, { useEffect, useState } from "react";
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import apiConfig from "../../configs/apiConfig";
import { API, HELPER } from "../../services";
import { isEmpty } from "../../services/helper";
import moment from "moment";
import io from "socket.io-client";
import { useSelector } from "react-redux";
import error400cover from "../../assets/images/no-data-found-page.png";
import { BottomScrollListener } from "react-bottom-scroll-listener";

const NotificationDropdown = () => {
  const [page, setPage] = useState(0);
  const [isNextListEmpty, setIsNextListEmpty] = useState(false);
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [totalNotification, setTotalNotification] = useState(0);

  const auth = useSelector((state) => state.auth.user);

  const toggleLeftCanvas = () => {
    setOpen(!open);
  };

  const getAllNotification = (reset = false) => {
    if (!isNextListEmpty || reset) {
      const filter = {
        page: reset ? 0 : page,
        rowsPerPage: 10,
      };

      API.get(apiConfig.notifications, filter)
        .then((res) => {
          const nextListEmpty = isEmpty(res.rows) || res.rows.length < 10;
          setIsNextListEmpty(nextListEmpty);
          if (reset) {
            setNotifications(res.rows);
            setPage(1);
          } else {
            setNotifications((prev) => [...prev, ...res.rows]);
            setPage((prev) => prev + 1);
          }
        })
        .catch(HELPER.showErrorToaster);
    }
  };

  const lazyLoadedMajorAuditList = () => {
    if (!isNextListEmpty) {
      getAllNotification();
    }
  };

  useEffect(() => {
    if (open) {
      getAllNotification(true);
    }
  }, [open]);

  const getUnreadNotifications = () => {
    API.get(apiConfig.notifications_unread)
      .then((res) => {
        setTotalNotification(res?.totalUnreadNoty);
      })
      .catch(HELPER.showErrorToaster);
  };

  const notificationOpen = () => {
    setOpen(true);
  };

  const notificationReadOpen = (notificationId) => {
    API.post(
      apiConfig.notifications_read.replace(":notification_id", notificationId)
    )
      .then((res) => {
        HELPER.toaster.success("Notification read successfully");
        getUnreadNotifications();
        // Update the state to reflect the change in wishlist status
        const updatedData = notifications?.map((item) => {
          if (item.id === notificationId) {
            return {
              ...item,
              is_read: item.is_read === false ? true : false,
            };
          }
          return item;
        });

        setNotifications(updatedData);

        // getAllNotification(true);
      })
      .catch(HELPER.showErrorToaster);
  };

  const removeNotification = (id) => {
    API.destroy(apiConfig.remove_notification.replace(":notification_id", id))
      .then((res) => {
        HELPER.toaster.success("Notification removed successfully");
        getAllNotification(true);
      })
      .catch(HELPER.showErrorToaster);
  };

  useEffect(() => {
    getUnreadNotifications();
  }, [auth?.user_id, auth?.employee_id]);

  const clearAllNotification = () => {
    API.post(apiConfig.notifications_clear_all)
      .then((res) => {
        HELPER.toaster.success("Cleared all notifications successfully");
        getAllNotification(true);
        getUnreadNotifications();
      })
      .catch(HELPER.showErrorToaster);
  };

  function loaderValidator() {
    if (!isNextListEmpty) {
      return "Fetching...";
    } else if (notifications.length === 0) {
      return "No Data Found";
    } else {
      return "All Data have been Fetched";
    }
  }

  function ensureNotificationPermission() {
    if (!("Notification" in window)) {
      console.error("This browser does not support desktop notifications");
      return false;
    }

    if (Notification.permission === "default") {
      return Notification.requestPermission().then((permission) => {
        return permission === "granted";
      });
    } else {
      return Promise.resolve(Notification.permission === "granted");
    }
  }

  function showNotification(data) {
    if (!HELPER.isEmpty(data)) {
      ensureNotificationPermission().then((permissionGranted) => {
        if (permissionGranted) {
          new Notification(data?.subject, {
            body: data?.content,
          });
        }
      });
    }
  }

  useEffect(() => {
    const socket = io(apiConfig.publicURL);
    const emit_name =
      auth?.role_name === "Employee"
        ? `employee-${auth?.employee_id}`
        : `admin-${auth?.user_id}`;

    if (auth?.user_id || auth?.employee_id) {
      socket.emit("room", emit_name);
      socket.on("notification", (data) => {
        showNotification(data?.data);
        getAllNotification(true);
        getUnreadNotifications();
      });
    } else {
      socket.emit("room", "guest-customer");
    }

    return () => {
      socket.disconnect();
    };
  }, [auth?.user_id, auth?.employee_id]);

  return (
    <React.Fragment>
      <button
        type="button"
        tag="button"
        className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
        onClick={notificationOpen}
      >
        <i className="bx bxs-bell fs-24 text-black"></i>
        <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-secondary">
          {totalNotification}
          <span className="visually-hidden">unread messages</span>
        </span>
      </button>
      <Offcanvas
        isOpen={open}
        toggle={toggleLeftCanvas}
        direction="end"
        className="notification-canvas"
      >
        <OffcanvasHeader
          className="d-flex align-items-center"
          toggle={toggleLeftCanvas}
        >
          <div className="d-flex align-items-center">
            <i className="bx bxs-bell fs-22 text-secondary"></i>{" "}
            <span className="ms-1 fs-12">Notification</span>
          </div>
        </OffcanvasHeader>
        {!!notifications.length && (
          <div className="p-3 text-end">
            <span
              className="cursor-pointer text-secondary text-decoration-underline"
              onClick={clearAllNotification}
            >
              Clear All Notifications
            </span>
          </div>
        )}
        <OffcanvasBody className="p-0">
          <BottomScrollListener onBottom={lazyLoadedMajorAuditList}>
            {(scrollRef) => (
              <div
                ref={scrollRef}
                className="infinite-scroll-notification-div"
                style={{ height: "calc(100% - 20px)", overflowY: "scroll" }}
              >
                <InfiniteScroll
                  dataLength={notifications.length}
                  next={lazyLoadedMajorAuditList}
                  hasMore={!isNextListEmpty}
                  loader={
                    <h4 className="loading-title">{loaderValidator()}</h4>
                  }
                >
                  {notifications &&
                    notifications?.map((item, i) => (
                      <div className="position-relative" key={i}>
                        <div
                          className="cursor-pointer notification-close-icon"
                          onClick={() => removeNotification(item?.id)}
                        >
                          <i className="ri-close-fill" />
                        </div>
                        <div className="notification-bell-icon">
                          <i
                            className={`ri-notification-2-fill ${
                              item?.is_read
                                ? "bell-color-read"
                                : "bell-color-unread"
                            }`}
                          />
                        </div>
                        <div
                          className={`notification-card cursor-pointer ${
                            item?.is_read
                              ? "border-read disabled-button"
                              : "border-unread"
                          }`}
                          onClick={() => notificationReadOpen(item?.id)}
                        >
                          <div className="notification-card-header">
                            <div className="d-flex align-items-center">
                              <i className="bx bxs-message-detail fs-20 text-secondary me-3" />
                              <span>{item?.subject}</span>
                            </div>
                          </div>
                          <div className="notification-card-body">
                            <p>{item?.content}</p>
                            <div className="time">
                              <span className="text-success">
                                {moment
                                  .utc(item?.createdAt)
                                  .local()
                                  .startOf("seconds")
                                  .fromNow()}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                  {notifications.length === 0 && (
                    <div className="d-flex justify-content-center mt-4">
                      <img src={error400cover} width="240px" />
                    </div>
                  )}
                </InfiniteScroll>
              </div>
            )}
          </BottomScrollListener>
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  );
};

export default NotificationDropdown;
