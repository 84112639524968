// import React from "react";
// import { Button, Spinner } from "reactstrap";

// const ReactButton = ({ loader, children, ...rest }) => {
//   return (
//     <Button disabled={loader ? true : false} {...rest}>
//       {loader ? (
//         <Spinner size="sm" className="me-2">
//           <>Loading... </>
//         </Spinner>
//       ) : null}
//       {children}
//     </Button>
//   );
// };

// export default ReactButton;

import React from "react";
import { Button, Spinner } from "reactstrap";

const ReactButton = ({ loader, children, ...rest }) => {
  return (
    <Button disabled={loader ? true : false} {...rest}>
      {loader ? <Spinner size="sm" className="me-2" /> : null}
      {children}
    </Button>
  );
};

export default ReactButton;
