import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API, HELPER } from "../../services";
import { ROUTES, apiConfig, appConfig } from "../../configs";
import moment from "moment";
import { AllPermissions, EmployeeInformalLeave } from "../../services/enums";
import { handleEmployeeShortLeave } from "../../slices/toolkit/auth";
import { store } from "../../slices";
import { UncontrolledTooltip } from "reactstrap";

const EmployeeShortLeaveProfileHeader = () => {
  const navigate = useNavigate();
  //  ---------------------- auth ------------------
  const auth = useSelector((state) => state.auth.user);

  const employeeShortLeaveData = useSelector(
    (state) => state?.auth?.employeeShortLeaveData
  );

  //  ---------------------- userPermissions ---------------------
  const userPermissions = useSelector(
    (state) => state?.auth?.user?.permissions
  );
  const employeeInformalLeavePermission = userPermissions?.find(
    (item) => item?.name === AllPermissions[23]?.name
  );
  const date = new Date();

  //  ------------------------- getShortEmployeeleaveData -------------
  const getShortEmployeeleaveData = () => {
    let payload = {
      leave_date: moment(date).format(appConfig.dateDisplayEditFormat),
      leave_status: EmployeeInformalLeave[0].value,
    };
    API.get(apiConfig.employeeShortLeave, payload)
      .then((res) => {
        store.dispatch(handleEmployeeShortLeave(res?.rows));
      })
      .catch(HELPER.showErrorToaster);
  };
  useEffect(() => {
    auth && auth?.role_name !== "Employee" && getShortEmployeeleaveData();
  }, []);
  return (
    <div>
      {auth &&
        auth?.role_name !== "Employee" &&
        employeeInformalLeavePermission?.view === 1 && (
          <a
            href="#employeeShortLeaveTable"
            className=" ms-4 d-flex justify-content-center wrapper-short-leave-img-div align-items-center cursor-pointer text-decoration-none"
            onClick={() => navigate(ROUTES.DASHBOARD)}
          >
            {employeeShortLeaveData &&
              employeeShortLeaveData?.map((items, keys) => (
                <div
                  className="wrapper-img-div blink-animation wraper-short-leave-profile"
                  key={keys}
                >
                  <img
                    src={HELPER.getProfileImage(
                      items.profile,
                      items.first_name,
                      items.last_name
                    )}
                    className="profile-img "
                    onError={(e) =>
                      HELPER.handleImageOnError(
                        e,
                        items.first_name,
                        items.last_name
                      )
                    }
                    // id={`tooltiples${keys}`}
                  />
                  <div className="hover-disaply-text ">
                    <span className="w-100">
                      {items?.first_name +
                        " " +
                        items?.middle_name?.charAt(0) +
                        ". " +
                        items?.last_name +
                        " " || ""}
                    </span>
                  </div>

                  {/* <UncontrolledTooltip
                        placement="top"
                        target={`tooltiples${keys}`}
                      >
                        {items?.first_name +
                          " " +
                          items?.middle_name?.charAt(0) +
                          ". " +
                          items?.last_name +
                          " " || ""}
                      </UncontrolledTooltip> */}
                </div>
              ))}
          </a>
        )}
    </div>
  );
};

export default EmployeeShortLeaveProfileHeader;
