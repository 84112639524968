import React, { Suspense, useEffect } from "react";
import withRouter from "../Components/Common/withRouter";

//redux
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import PageLoader from "../Components/Common/PageLoader";
import { HELPER } from "../services";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../configs";

const NonAuthLayout = ({ children }) => {
  const history = useNavigate();
  const nonauthData = createSelector(
    (state) => state.Layout.layoutModeType,
    (layoutModeType) => layoutModeType
  );
  // Inside your component
  const layoutModeType = useSelector(nonauthData);

  useEffect(() => {
    if (layoutModeType === "dark") {
      document.body.setAttribute("data-bs-theme", "dark");
    } else {
      document.body.setAttribute("data-bs-theme", "light");
    }
    return () => {
      document.body.removeAttribute("data-bs-theme");
    };
  }, [layoutModeType]);

  useEffect(() => {
    if (HELPER.isAuth()) {
      // history(ROUTES.DASHBOARD)
      history(ROUTES.EMPLOYEE);
    }
  }, []);

  return (
    <div>
      <Suspense fallback={<PageLoader />}>{children}</Suspense>
    </div>
  );
};

export default withRouter(NonAuthLayout);
