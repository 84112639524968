const appConfig = {
  host: process.env.REACT_APP_API_HOST,
  prefix: process.env.REACT_APP_API_PREFIX,
  version: process.env.REACT_APP_API_VERSION,
  nodeEnv: process.env.NODE_ENV,

  dateDisplayFormat: "DD/MM/YYYY",
  dateFormat: "DD-MM-YYYY",
  dateDisplayEditFormat: "YYYY-MM-DD",
  dateAndTimeDisplayFormat: "DD/MM/YYYY HH:mm A",
  dateAndTimeDisplayDashedFormat: "DD-MM-YYYY HH:mm A",
  dateFormatWithMonth: "DD MMMM, YYYY",
  dateFormatShortMonth: "DD MMM, YYYY",
  dateFormatShortMonthWithTime: "DD MMM, YYYY HH:mm A",
  dateAndTimeFormat: "DD-MM-YYYY HH:mm A",
  dateAndTimeFormat12Hours: "DD-MM-YYYY hh:mm A",
  dateAndTimeDisplay: "YYYY/MM/DD HH:mm",
  timeDisplay: "HH:mm",
  monthYear: "YYYY-MM",
  displayDay: "ddd",
  timeOnly24Hours: "HH:mm A",
  defaultPerPage: 9999999,
  localStorage: {
    token: "attendance-bearer-token",
  },

  persistKey: {
    authReducer: "auth",
  },
  default_pagination_state: {
    page: 0,
    rowsPerPage: 10,
  },
};

export default appConfig;
